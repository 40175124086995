<!--
 * @Autor: syq
 * @Date: 2021-07-09 17:31:23
-->
<template>
  <div class="tabbar">
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" to="/storehome">店铺首页</van-tabbar-item>
      <van-tabbar-item icon="apps-o" to="/storeclassification"
        >店铺分类</van-tabbar-item
      >
      <van-tabbar-item icon="shop-o" to="/storedetails"
        >店铺详情</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      active: this.$route.name,
    };
  },
  mounted() {
    const path = this.$route.path;

    this.active = {
      "/storehome": 0,
      "/storeclassification": 1,
      "/storedetails": 2,
    }[path];
  },
};
</script>

<style lang="less">
.tabbar {
  width: 100%;
}
</style>
