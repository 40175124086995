<!--
 * @Autor: syq
 * @Date: 2021-07-09 21:11:27
-->
<template>
  <div id="storehomelayout">
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="tabbar">
      <Tabbar></Tabbar>
    </div>
  </div>
</template>

<script>
import Tabbar from "../components/Tabbar.vue";
export default {
  components: { Tabbar },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less">
#storehomelayout {
  width: 100%;
  height: 100vh;
  display: flex;
  background: @BackgroundColor;
  flex-direction: column;
  .main {
    flex: 1;
  }
  .tabbar {
    height: 50px;
  }
}
</style>
